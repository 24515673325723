body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  text-align: left;
  font-size: 1rem;
  padding: .6rem 1rem .6rem 1rem;
  border-bottom: 1px solid #505050;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #101010;
  flex-shrink: 0;
}

.portfolio-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  margin: 0 1em 2em 1em;
}

.darkModeButton {
  cursor: pointer;
}

.logo-image {
  max-height: 3.25rem;
  width: auto;
}

.logo-text {
  max-height: 3.25rem;
  width: auto;
}

@media screen and (max-width: 700px) {
  .logo-text {
    display: none;
  }
}

/*If we don't do this, the last row in the grid will be obscured by what appears to be a placeholder for a
scrollbar. I don't care enough to investigate further
https://stackoverflow.com/questions/52767610/how-to-always-show-the-horizontal-scroll-bar-in-ag-grid
 */
.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.1px;
  height: 0.1px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.external-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-left: .2rem;
  margin-right: .2rem;
  opacity: .8;
  transition: opacity .1s ease-in;
  background: #fff;
  border: 1px solid #fff;
  border-radius: .5rem;
}

.external-icon:hover {
  opacity: 1;
}

.ag-theme-balham-dark .ag-row .ag-cell-data-changed {
  background-color: rgba(192,192,192,.2) !important;
}

a { text-decoration: none; }
a:hover { text-decoration: none; color: inherit; }
a:focus { text-decoration: none; color: inherit; }
a:active { text-decoration: none; color: inherit; }
a:visited {text-decoration: none;color: inherit; }

:any-link {
  color: #fff ;
}

.dashboard-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.portfolio-progress {
  margin-top: 2rem;
}