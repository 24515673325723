.landing-main {
    height:100%;
    display: flex;
    background:url(images/bull.jpg) rgba(64,64,64, 0.6);
    background-size:cover;
    background-blend-mode: multiply;
    background-position: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #FFFFFF;
    width: 100vw;
    text-align: center;
}

h1 {
    text-shadow: 1px 1px 15px #000000;
}

.learn-more-button {
    margin-right: 1rem !important;
}

a {
    text-decoration: none !important;
}
